export default [
  {
    path: "/super-admin",
    name: "super-main",
    component: () => import("@/pages/superadmin/main.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "dashboard",
        name: "super-dashboard",
        component: () => import("@/pages/superadmin/dashboard.vue"),
      },
      {
        path: "survey",
        name: "super-survey",
        component: () => import("@/pages/superadmin/survey.vue"),
        children: [
          {
            path: "",
            name: "super-survey-firstPage",
            component: () => import("@/pages/superadmin/survey/survey.vue"),
          },
          {
            path: "new",
            name: "super-servey-new",
            component: () => import("@/pages/superadmin/survey/newSurvey.vue"),
          },
          {
            path: ":id",
            name: "super-specific-survey",
            component: () =>
              import("@/pages/superadmin/survey/surveyItem/surveyItem.vue"),
          },
          {
            path: ":id/new",
            name: "super-new-survey",
            component: () =>
              import(
                "@/pages/superadmin/survey/surveyItem/surveyDetails/AddNewTool/addNewTool.vue"
              ),
          },
          {
            path: ":surveyId/tool/:id",
            name: "super-survey-tool",
            component: () =>
              import(
                "@/pages/superadmin/survey/surveyItem/surveyDetails/surveyTool.vue"
              ),
          },
          {
            path: "tool/:id/priority",
            name: "super-survey-tool-priority",
            component: () =>
              import(
                "@/pages/superadmin/survey/surveyItem/surveyDetails/priority.vue"
              ),
          },
        ],
      },
      {
        path: "users",
        name: "super-users",
        component: () => import("@/pages/superadmin/users_teams/users.vue"),
      },
      {
        path: "teams",
        name: "super-teams",
        component: () => import("@/pages/superadmin/users_teams/teams.vue"),
      },
      {
        path: "bulk/sms",
        name: "super-bulk",
        component: () => import("@/pages/superadmin/bulkSms.vue"),
      },
      // {
      //   path: "bulk/email",
      //   name: "super-bulk-email",
      //   component: () => import("@/pages/superadmin/bulkEmails.vue"),
      // },
      {
        path: "approve",
        name: "super-approve",
        component: () => import("@/pages/superadmin/approveSms.vue"),
      },
      {
        path: "billing",
        name: "super-billing",
        component: () => import("@/pages/superadmin/billing/MainBilling.vue"),
        children: [
          {
            path: "plans",
            name: "super-billing-plans",
            component: () => import("@/pages/superadmin/billing/plans.vue"),
          },
          {
            path: "payment/info",
            name: "super-billing-payment-info",
            component: () =>
              import("@/pages/superadmin/billing/paymentInfo.vue"),
          },
        ],
      },
      {
        path: "reminder",
        name: "super-reminder",
        component: () => import("@/pages/superadmin/reminder/reminder.vue"),
      },
      {
        path: "profile",
        name: "super-profile",
        component: () => import("@/pages/superadmin/profile.vue"),
      },
      {
        path: "manage/users",
        name: "super-manage",
        component: () => import("@/pages/superadmin/manageUsers.vue"),
      },
      {
        path: "support",
        name: "super-support",
        component: () => import("@/pages/superadmin/contactSupport.vue"),
      },
      {
        path: "investigator",
        name: "super-investigator",
        component: () => import("@/pages/moh/investigatorMain.vue"),
      },
      {
        path: "investigator/:id",
        name: "super-investigator-info",
        component: () => import("@/pages/moh/investigatorData.vue"),
      },
    ],
  },
  {
    path: "/test",
    name: "my-test",
    component: () => import("@/pages/test.vue"),
  },
];
