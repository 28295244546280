<template>
  <transition name="fade">
    <div class="logoContainer" v-if="isVisible">
      <div class="preloaderBg" id="preloader" onload="preloader()">
        <div class="loader-container mt-[10%]">
          <div class="preloader">

          </div>
          <div class="preloader2"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      isVisible: true,
    };
  },
  mounted() {
    window.addEventListener("load", this.hidePreloader);
  },
  beforeUnmount() {
    window.removeEventListener("load", this.hidePreloader);
  },
  methods: {
    hidePreloader() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.preloaderBg {
  position: fixed;
  z-index: 9999999999999999999;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;

  text-align: center;
}

.preloader {
  margin: auto;
  background: url("/src/assets/images/logoImage.svg") no-repeat center;
  background-size: 150px;
  width: 300px;
  height: 300px;
}

.preloader2 {
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--primary-color);
  border-radius: 50%;
  width: 250px;
  height: 250px;
  animation: spin 1s ease-in-out infinite;
  position: relative;
  margin: auto;
  top: -280px;
  z-index: 9999999999999999999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
