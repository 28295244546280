export default [
  {
    path: "",
    name: "home",
    component: () => import("@/pages/homePage.vue"),
    meta: {
      title: "Home",
    },
  },
  {
    path: "/home",
    name: "myHome",
    component: () => import("@/pages/home.vue"),
    meta: {
      title: "Home",
    },
  },
  {
    path: "/support",
    name: "support",
    component: () => import("@/pages/contactSupport/ContactForm.vue"),
    meta: {
      title: "Contact support",
    },
  },
  {
    path: "/v1/documentation",
    name: "documentation",
    component: () => import("@/pages/documentation/documentation.vue"),
    meta: {
      title: "Documentation",
    },
  },
  {
    path: "/chart",
    name: "chart",
    component: () => import("@/components/mainCharts/Echarts/gaugeChart.vue"),
    meta: {
      title: "Contact support",
    },
  },
  {
    path: "/test",
    name: "testign",
    component: () => import("@/pages/test.vue"),
  },
];
