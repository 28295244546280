<template>
  <!-- <n-message-provider> -->
    <preloader />
    
  <router-view />
  <!-- </n-message-provider> -->
</template>

<script>
import { mapActions } from "vuex";
import preloader from "@/components/preloader.vue";

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      setUser: "setUser",
      setToken: "setToken",
    }),

    // handleLoad() {
    //   this.loading = false;
    // },
  },
  components: {
    preloader,
  },

  beforeMount() {
    if (localStorage.getItem("iLi")) {
      this.setUser(JSON.parse(localStorage.getItem("user")));
      this.setToken(localStorage.getItem("tk"));
    } else if (sessionStorage.getItem("iLi")) {
      this.setUser(JSON.parse(sessionStorage.getItem("user")));
      this.setToken(sessionStorage.getItem("tk"));
    }
  },
  mounted() {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
html {
  scroll-behavior: smooth;
}
:root {
  /* Primary Colors */
  --primary-color: #6c63ff;
  --primary-color-dark: #5750da;

  /* Text Colors */
  --text-color: #5d6e8b;
  --text-color-light: #9eaac2;
  --text-color-dark: #191d23;

  /* Danger Color */
  --danger-color: #ff6464;
  --danger-color-dark: #da5252;

  /* Links Color */
  --link-color: #108ac5;

  /* Background-color */

  // Home page design

  --primary: #6c63ff;
  --primary-30: rgba(156, 152, 241, 0.3);
  --primary-8: rgb(107, 99, 255, 0.08);
  --primary-hover: #5b55df;
  --black: #100d4a;
  --white: white;
  --white-80: rgba(255, 255, 255, 80%);
  --white-10: rgba(255, 255, 255, 10%);
  --gray: #9eaac2;
  --gray-10: rgb(158, 170, 194, 10%);
  --gray-30: rgb(158, 170, 194, 30%);
  --gray-2: #5d6e8b;
  --primary: #6c63ff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: "Rubik", sans-serif;
  color: var(--text-color-dark);
}

.datepicker-container {
  .n-date-picker {
    .n-input {
      --n-height: 50px !important ;
    }
  }
}

.n-tabs {
  --n-tab-color-segment: var(--primary-color) !important;
}

.n-spin {
  --n-color: white !important;
}
.n-tabs-tab--active {
  

  .n-tabs-tab__label {
    color: white !important;
  }
}
.newSurveyInputs {
  .n-input {
    --n-height: 50px !important;
  }
  .n-select {
    .n-base-selection {
      --n-height: 50px !important;
    }
  }
}

.select-containers {
  .n-select {
    width: 50% !important;
  }
}

.teams-input {
  .n-select {
    .n-base-selection {
      --n-height: 50px !important;
      --n-border-radius: 6px !important;
    }
  }
}

.customNaiveInput {
  .n-input {
    --n-height: 50px !important;
  }
  .n-select {
    .n-base-selection {
      --n-height: 50px !important;
      --n-border-radius: 6px !important;
    }
  }
}

.n-base-selection {
  --n-border: 1px solid #f0f4fd !important;
  --n-border-active: 1px solid var(--primary-color) !important;
  --n-border-focus: 1px solid var(--primary-color) !important;
  --n-border-hover: 1px solid var(--primary-color) !important;
  --n-caret-color: var(--primary-color) !important;
  --n-placeholder-color: var(--text-color) !important;
}

.n-input {
  --n-border: 1px solid #f0f4fd !important;
  --n-border-focus: 1px solid var(--primary-color) !important;
  --n-border-hover: 1px solid var(--primary-color) !important;

  --n-placehoder {
    font-weight: 200px;
  }

  .n-input__placeholder {
    color: var(--text-color) !important;
    span {
      color: var(--text-color) !important;
    }
  }

  &:focus {
    border: 1px solid var(--primary-color) !important;
  }
}

.n-pagination
  .n-pagination-item:not(
    .n-pagination-item--disabled
  ).n-pagination-item--active {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  display: flex;
  justify-content: center;
}

.n-checkbox {
  --n-border-checked: 1px solid var(--primary-color) !important;
  --n-border-focus: 1px solid var(--primary-color) !important;

  --n-color-checked: var(--primary-color) !important;
}

.n-date-panel {
  --n-item-color-active: var(--primary-color) !important;
  --n-item-color-included: rgba(108, 99, 255, 0.1) !important;
}

.n-form-item-feedback-wrapper {
  display: none !important;
}

.n-form-item-label__text {
  color: #0f3e55 !important;
}

// .n-input:focus {
//   border-color: var(--primary-color); /* Apply custom focus border color */
// }
::selection {
  background-color: var(--primary-color);
  color: white;
}
</style>
