import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import naive from "naive-ui";
import "./registerServiceWorker";
import "@/assets/css/styles.css";
// main.js
import "leaflet/dist/leaflet.css";

import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import HighchartsVue from "highcharts-vue";
import VueApexCharts from "vue3-apexcharts";

import ECharts from "vue-echarts";
import "echarts";

import { GridLayout, GridItem } from "grid-layout-plus";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'



const toastOption = {
  transition: "Vue-Toastification__fade",
  position: POSITION.TOP_CENTER,
};

createApp(App)
  .use(Toast, toastOption)
  .use(naive)
  .use(VueApexCharts)
  .component('VueDatePicker', VueDatePicker)
  .component("GridLayout", GridLayout)
  .component("GridItem", GridItem)
  .component("apexchart", VueApexCharts)
  .component("v-chart", ECharts) // Register ECharts globally
  .component("echart", ECharts) // Register ECharts globally
  .use(HighchartsVue)
  .use(store)
  .use(router)
  .mount("#app");
