export default [
  // {
  //   path: "/",
  //   redirect: "/login",
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/auth/login.vue"),
    meta: { title: "Login" },
  },
  {
    path: "/request",
    name: "request",
    component: () => import("@/pages/auth/request.vue"),
    meta: { title: "Request" },
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () => import("@/pages/auth/forgot.vue"),
    meta: { title: "Forgot Password" },
  },
  {
    path: "/verification",
    name: "otp",
    component: () => import("@/pages/auth/otp.vue"),
    meta: { title: "OTP Verification" },
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("@/pages/auth/reset.vue"),
    meta: { title: "New Password" },
  },
];
