import { createStore } from "vuex";
import { io } from "socket.io-client";

export default createStore({
  state: {
    token: null,
    user: null,
    organisation: null,
    messagesLeft: 0,
    logo: null,
    selectedLanguages: [],
    questionList: [],
    surveyLanguages: [],
    chatSocket: null,
    notificationSocket: null,
  },
  getters: {
    getChatSocket(state) {
      return state.chatSocket;
    },
    getNotificationSocket(state) {
      return state.notificationSocket;
    },
    getSurveyLanguages(state) {
      return state.surveyLanguages;
    },
    getQuestionList(state) {
      return state.questionList;
    },
    getSelectedLanguages(state) {
      return state.selectedLanguages;
    },
    getUser(state) {
      return state.user;
    },
    getOrganisation(state) {
      return state.organisation;
    },
    getMessagesLeft(state) {
      return state.messagesLeft;
    },
    getLogo(state) {
      return state.logo;
    },
  },
  mutations: {
    SET_CHAT_SOCKET(state, payload) {
      state.chatSocket = payload;
    },
    SET_NOTIFICATION_SOCKET(state, payload) {
      state.notificationSocket = payload;
    },
    SET_SURVEY_LANGUAGES(state, payload) {
      state.surveyLanguages = payload;
    },
    SET_QUESTION_LIST(state, payload) {
      state.questionList = payload;
    },
    SET_SELECTED_LANGUAGES(state, payload) {
      state.selectedLanguages = payload;
    },
    REDUCE_MESSAGES_LEFT(state, payload) {
      state.messagesLeft -= payload;
    },
    SET_MESSAGES_LEFT(state, payload) {
      state.messagesLeft = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_USER(state, payload) {
      state.user = {
        id: payload.id,
        email: payload.email,
        userName: payload.userName,
      };
    },
    LOGOUT_USER(state, payload) {
      state.user = null;
      state.token = null;
    },
    SET_ORGANISATION(state, payload) {
      state.organisation = payload;
    },
    SET_LOGO(state, payload) {
      state.logo = payload;
    },
  },
  actions: {
    setChatSockets({ commit }) {
      let userId;

      if (sessionStorage.getItem("iLi")) {
        userId = JSON.parse(sessionStorage.getItem("user")).id;
      } else {
        userId = JSON.parse(localStorage.getItem("user")).id;
      }

      const chatConnection = io("https://endpoints.surveyhub.rw/support", {
        query: {
          userId: userId,
          userType: "USER",
        },
        transports: ["websocket", "polling"],
        withCredentials: true,
        extraHeaders: {
          "Content-Type": "application/json",
        },
      });
      commit("SET_CHAT_SOCKET", chatConnection);
    },
    setNotificationSockets({ commit }) {
      let userId;

      if (sessionStorage.getItem("iLi")) {
        userId = JSON.parse(sessionStorage.getItem("user")).id;
      } else {
        userId = JSON.parse(localStorage.getItem("user")).id;
      }

      const NotificationConnection = io(`https://endpoints.surveyhub.rw`, {
        query: {
          userId: userId,
        },
        transports: ["websocket", "polling"],
        withCredentials: true,
      });
      commit("SET_NOTIFICATION_SOCKET", NotificationConnection);
    },
    setQuestionList({ commit }, payload) {
      commit("SET_QUESTION_LIST", payload);
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    logoutUser({ commit }) {
      commit("LOGOUT_USER");
    },
    setOrganisation({ commit }, payload) {
      commit("SET_ORGANISATION", payload);
    },
    reduceMsgLeft({ commit }, payload) {
      commit("REDUCE_MESSAGES_LEFT", payload);
    },
    setMsgLeft({ commit }, payload) {
      commit("SET_MESSAGES_LEFT", payload);
    },
    setLogo({ commit }, payload) {
      commit("SET_LOGO", payload);
    },
    setSelectedLanguages({ commit }, payload) {
      commit("SET_SELECTED_LANGUAGES", payload);
    },
    setSurveyLanguages({ commit }, payload) {
      commit("SET_SURVEY_LANGUAGES", payload);
    },
  },

  modules: {},
});
